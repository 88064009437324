
.leased_card {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 2px;
    border-radius: 5px;
    background-image: radial-gradient( circle 860px at 11.8% 33.5%,  rgba(240,30,92,1) 0%, rgba(244,49,74,1) 30.5%, rgba(249,75,37,1) 56.1%, rgba(250,88,19,1) 75.6%, rgba(253,102,2,1) 100.2% );
}


.market_card { 
    display: flex;
    flex-shrink: 0;
    width: 100%;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 2px;
    border-radius: 5px;
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);

}

.owned_card {	
    display: flex;
    flex-shrink: 0;
    width: 100%;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 2px;
    background-color: #01b75f;
    background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
    
    }


.pentagon {
    -webkit-clip-path: polygon(50% 0%, 100% 40%, 82% 100%, 18% 100%, 0% 40%);
    		clip-path: polygon(50% 0%, 100% 40%, 82% 100%, 18% 100%, 0% 40%);
}

.hexagon {
    -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    		clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.heptagon {
    -webkit-clip-path: polygon(50% 0%, 90% 20%, 100% 62%, 75% 100%, 25% 100%, 0% 62%, 10% 20%);
    		clip-path: polygon(50% 0%, 90% 20%, 100% 62%, 75% 100%, 25% 100%, 0% 62%, 10% 20%);
}

.octagon {
    -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    		clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
}

.nonagon {
    -webkit-clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
    		clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
}

.decagon {
    -webkit-clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 65%, 80% 90%, 50% 100%, 20% 90%, 0 65%, 0 35%, 20% 10%);
    		clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 65%, 80% 90%, 50% 100%, 20% 90%, 0 65%, 0 35%, 20% 10%);
}

.circle {
    -webkit-clip-path: circle(50% at 50% 50%);
    		clip-path: circle(50% at 50% 50%);
}

.ellipse{
    -webkit-clip-path: ellipse(35% 50% at 50% 50%);
    		clip-path: ellipse(35% 50% at 50% 50%);
}

.triangle{
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    		clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.rhombus {
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    		clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.trapezoid {
    -webkit-clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
    		clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
}

.parallelogram {
    -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    		clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.bevel {
    /*
        clip-path: polygon(0 0, 100% 0, 100% 26%, 100% 94%, 80% 100%, 20% 100%, 1% 94%, 0 24%);
    */
    border-radius: 20px;
}

.rabbet {
    -webkit-clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
    		clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
}

.sign-left {
    -webkit-clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
    		clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
}

.sign-right {
    -webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
    		clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

.chevron-left {
    -webkit-clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    		clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}

.chevron-right {
    -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    		clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}

