/* body{
  background-image: url('../../images/bullrush_bg.jpg');
  background-color: rgba(0,0,0,0.9);
  background-size: cover;
  background-position: center;
  max-width: "100%";
  max-height: '100%';
  margin: 0;
  position: relative;
  background-color: #0f111f;
  max-width: "100%";
  margin: 0
} */


[class^="slider-single "] {
    border: none !important;
    background: transparent;
    color: transparent;
    box-shadow: 0px;
    opacity: distanceFactor * distanceFactor
    
  }

.slider-single-content {
    border: none;
    background: transparent;
    color: transparent;
    box-shadow: none;
    opacity: distanceFactor * distanceFactor
    
  }

  .react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
    box-shadow: none !important;
  }


  .react-3d-carousel .slider-navigation {
    display: none;
}

.hide-navigation-arrows .slider-navigation {
    display: none;
  }

.slider-left > div {
    display: none;
  }
.slider-right > div {
    display: none;
}


.carousel-container .slider-left > div,
.carousel-container .slider-right > div {
    display: none;
}


.MuiStack-root .css-1nza621-MuiStack-root {
    margin-top: 10px;
}

  
