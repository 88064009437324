body{
    background-color: #0f111f;
    max-width: "100%";
    margin: 0
}

.add_tint {
    background-color: rgba(0, 0, 0, 0.9);
}


*{
    -ms-overflow-style: none;
    font-family: 'Roboto Slab';
}

::-webkit-scrollbar {
    display: none;
}


.trait_type_select{
    border-color: '#fff';
    background-color: '#fff';
}


@font-face{
    font-family: 'Space-Mission-Light';
    src: url(../theme/fonts/SpaceMission-rgyw9.otf) format('opentype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}


@font-face{
    font-family: 'Space-Mission-Regular';
    src: url(../theme/fonts/SpaceMission-rgyw9.otf) format('opentype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face{
    font-family: 'Space-Mission-Semibold';
    src: url(../theme/fonts/SpaceMission-rgyw9.otf) format('opentype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face{
    font-family: 'Space-Mission-Bold';
    src: url(../theme/fonts/SpaceMission-rgyw9.otf) format('opentype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}



@font-face{
    font-family: 'Mineblox-BRF';
    src: url(../theme/fonts/Mineblox-0WoAX.ttf) format('truetype');
    font-weight:  700;
    font-display: swap;
    font-style: normal;
}


@font-face{
    font-family: 'DiloWorld';
    src: url(../theme/fonts/DiloWorld-mLJLv.ttf) format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face{
    font-family: 'Plaguard';
    src: url(../theme/fonts/Plaguard-ZVnjx.otf) format('opentype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face{
    font-family: 'Zelot-Light';
    src: url(../theme/fonts/zelot/ZealotLight-mx9m.ttf) format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face{
    font-family: 'Zelot-College';
    src: url(../theme/fonts/zelot/ZealotLight-mx9m.ttf) format('truetype');
    font-weight: 100;
    font-display: swap;
    font-style: normal;
}


@font-face{
    font-family: 'Zelot';
    src: url(../theme/fonts/zelot/ZealotOutline-rnMy.ttf) format('truetype');
    font-weight: 100;
    font-display: swap;
    font-style: normal;
}


@font-face{
    font-family: 'Zebulon';
    src: url(../theme/fonts/zebulon/Zebulon-wxRn.ttf) format('truetype');
    font-weight: 100;
    font-display: swap;
    font-style: normal;
}

@font-face{
    font-family: 'AZN';
    src: url(https://brfstatic1.s3.amazonaws.com/azn/AznKnucklesTrial-z85pa.otf) format('opentype');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
}

@font-face{
    font-family: 'Dosis', sans-serif;
    src: url(../theme/fonts/Dosis-VariableFont_wght.ttf) format('truetype');    font-weight: 900;
    font-display: swap;
    font-style: normal;
}

@font-face{
    font-family: 'Roboto Slab', sans-serif;
    src: url(../theme/fonts/RobotoSlab-VariableFont_wght.ttf) format('truetype');    font-weight: 900;
    font-display: swap;
    font-style: normal;
}










